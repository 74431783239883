<script lang="ts" setup>
import { DemosService } from "classes/models/demos/demos-service";
import { ClassroomEmailsService } from "~/classes/models/classroom-emails/classroom-emails.service";
import { StudentLinkedAccounts } from "~/classes/models/studentLinkedAccounts/student-linked-account.model";
import { StudentsService } from "~/classes/models/students/students.service";

const userClassroomsData = useUserClassroomsDataStore();
const { classrooms, allAssignments, allStudents } =
  storeToRefs(userClassroomsData);

const isCreatingDemo = ref(false);
const createDemo = async () => {
  isCreatingDemo.value = true;

  const allDocuments = await DemosService.fetchAllUserDocuments(
    useCurrentUID()!
  );

  const allStudentCriteriaTransactions =
    await DemosService.fetchAllStudentCriteriaTransactions(useCurrentUID()!);

  const classroomEmails = await ClassroomEmailsService.list(useCurrentUID()!, {
    includeInfo: true,
  });

  const studentLinkedAccounts: StudentLinkedAccounts = [];

  for (const classroom of classrooms.value) {
    const accounts = await StudentsService.listClassroomStudentLinkedAccounts({
      classroomId: classroom.id!,
      userId: useCurrentUID()!,
    });

    studentLinkedAccounts.push(...accounts);
  }

  await DemosService.createDemo(
    classroomEmails,
    classrooms.value,
    allAssignments.value,
    allStudents.value,
    studentLinkedAccounts,
    allDocuments,
    allStudentCriteriaTransactions
  );

  isCreatingDemo.value = false;
};
</script>

<template>
  <BaseTextButton
    class="mr-2"
    :show-spinner="isCreatingDemo"
    @click="createDemo"
  >
    Create Demo
  </BaseTextButton>
</template>

<style scoped></style>
